.files-container{
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
.form-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.form-description{
    border-left: 1px solid;
}
.files-form form {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;
    justify-content: center;
}
.inputfield.input-file {
    height: 250px;
    /* border: 1px solid; */
    border-radius: 1rem;
    padding: 1.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 1px 2px 6px 2px #00000033;
}
.inputfield.input-file .title{
    background-color: transparent;
    font-size: 1rem;
}
input[type="file" ] {
    color: transparent;
    border: none;
}

.file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
.file-input::-webkit-input-placeholder {
    visibility: hidden;
  }
  .files-form .file-input::before {
    content: attr(data-before);
    display: inline-block;
    background: #216ba5;
    color: white;
    border: 2px solid transparent;
    border-radius: 3px;
    padding: 1rem;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-size: 10pt;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    width: 50%;
    text-align: center;
    transition: all .5s ease;
    opacity: 1;
    box-shadow: 2px 2px 1px 0px #24180785;
  }
  .file-input:hover::before {
    box-shadow: 1px 2px 1px 1px #241807e5;
  }
  .file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
  label.title{
      text-transform: uppercase;
      margin-bottom: 2rem;
      text-align: center;
  }
  .input-file {
     display: flex;
     position: relative;
     height: 100%;
     flex-direction: column;
     align-content: center;
  }
  .input-file  ul{
     position: absolute;
     bottom: 0;
  }
  .inputfield.botones {
    margin: auto;
    flex-basis: 100%;
    text-align: center;
}
.upload-file{
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;
}