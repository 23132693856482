.auth-container{
    min-height: 100vh;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    flex-direction: column;
    /* padding: 2rem 0; */
    flex-wrap: wrap;
    /* align-content: stretch; */
    justify-content: space-around;
    gap: 3.5rem;
}
main{
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    justify-content: space-around;
}


.left, .right {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    text-align: center;
}
.left .logo,
.left .logo img{
    /* width: 90%; */
    width: 100%;
    max-width: 400px;
}
.left .logo {
    margin-top: 2rem;
}
.right .form-container {
    width: 90%;
    max-width: 500px;
    padding: 2rem;
    border: 1px solid #cecece;
    border-radius: 1rem;
    box-shadow: 3px 3px 10px 0px;
    min-height:auto ;
}
.form-container form {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
form input[type=email], form input[type=password], form #sign-up-name{
    text-transform: none;
}
.form-container form .btn{
    margin-bottom: 0;
}
.form-field{
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    width: 100%;
}
.form-field label{
    font-size: larger;
    margin-bottom: 0.3rem;
    font-weight: bold;
    text-transform: uppercase;
    font-family: sans-serif;
    color: var(--azul);
    text-align: left;
}

.form-field input{
    padding: 0.5rem 1rem;
    border-radius: 8px;
    border: 2px solid #b9b9b9;
    width: 100%;
    outline: none;
    outline-offset: 0;
}
.form-field input:focus,
.form-field input:focus-visible{
    border: 2px solid var(--azul);
    outline-offset: 0;
}
.hide {
    display: none !important;
}
section.search-date {
    padding: 1rem 2rem;
    border: 1px solid var(--azul-claro);
    border-radius: 1rem;
    /* display: flex; */
    height: auto;
}
section .form-field {
    flex-direction: row;
    align-items: baseline;
    gap: 1rem;
    position: relative;
    /* height: 30px; */
}
.search-date .btn-primary{
    margin: 0;
    position: absolute;
    right: 0;
    padding: 0.6rem 1rem;
    height: 100%;
    border-radius: 0 8px 8px 0;
}

.modal-flyer {
    position: absolute;
    inset: 0;
    z-index: 11;
    background: #8080804a;
}
.modal-flyer img{
    position: fixed;
    z-index: 11;
    margin: auto;
    left: 50%;
    height: 95%;
    transform: translateX(-50%);
}
.modal-flyer .modal{
    position: fixed;
    margin: auto;
    left: 50%;
    top: 1rem;
    height: 100%;
    transform: translateX(-50%);
}
.modal-flyer .modal .close{
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 13;
    cursor: pointer;
}

@media (max-width: 700px) {
    .auth-container main{
        flex-direction: column;
    }
    
  }
  