nav{
    position: fixed;
    width: 100%;
    background: white;
    z-index: 10;
    top: 0;
}

.nav-links{
    display: flex;
    width: 100%;
}

.nav-links ul {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    margin: 0;
    flex-direction: row;
}

.nav-links ul img {
    max-height: 95px;
}
.nav-links li,
.nav-links li a {
    list-style: none;
    text-decoration: none;
    color: var(--text);
    font-family:'Montserrat', sans-serif; 
    text-transform: uppercase;
    font-size: 14px;
    transition: color .2s ease;
}
.nav-links li a.active {
    color: var(--oro);
    border-bottom: 3px solid;
}
.nav-links li a:hover {
    color: var(--oro);
}

hr {
    background-color: var(--azul-claro);
    color: burlywood;
    height: 2px;
    border: none;
    margin: 0;
}