.contact-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.contact-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    margin: 2rem 0;
}
.contact-header *{
    margin: 0;
}
.contact-header .subtitle h1{
    text-decoration: underline;
    color: var(--azul-claro);
}
.contact-form .inputfield {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: baseline;
    gap: .5rem;
}
.contact-form {
    width: 100%;
}
.contact-form  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 50%;
    margin: 0 auto;
    align-items: center;
    max-width: 500px;
}
.contact-form .inputfield input,
.contact-form .inputfield textarea {
    width: 100%;
    padding: .5rem 1rem;
    resize: none;

}
.contact-form .inputfield.btn-field {
    place-content: flex-end;
}