@tailwind base;
@tailwind components;
@tailwind utilities;
:root{
    --orange: #FF8600;
    --azul: #002B49;
    --azul-active: hsl(205, 100%, 85%);
    --azul-claro: hsl(205deg 58% 75%);
    --azul-cards: rgb(221, 239, 255);
    --oro: #BC8D51;
    --error: #b61b00;
    --textColor: #323a41;
    --colorBtn: white;
}
#root{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 7rem;
    align-items: center;
}
body{
    margin: 0;
    padding: 0;
    color: var(--textColor);
    position: relative;
}
.nl_logo{
    width: 95px;
}

*{
    box-sizing: border-box;
    outline: none !important;
    font-family:'Montserrat', sans-serif; 
    
}

.btn {
    cursor: pointer;
    text-decoration-line: none;
}
.btn-primary{    
padding: .5rem 1rem;    
border: none;   
background: var(--azul);  
color: var(--colorBtn);    
border-radius: 1rem;    
text-size-adjust: none;
align-self: end;   
text-decoration-line: none;
transition: all 0.3s ease;   
border: 1.5px solid transparent;
}
.btn.btn-primary:hover {
    background-color: var(--azul-cards);
    color: var(--azul);
    border: 1.5px solid var(--azul);
}
.btn-secundary{
    padding: .5rem 1rem;
    border: none;
    background: none;
    color: var(--azul);
    font-family:'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: 14px;
    transition: color ease .5s;
    border-bottom: 0px solid;
}
form input[type="email"]{
    text-transform: none;
}
.btn-secundary:hover{
    color: var(--azul-claro);
}
.overlay {
    position: absolute;
    inset: 0;
    background-color: #70707046;
    z-index: 4;
}
.container{
    padding: 3rem 5vh 3rem 5vh;
}
.hide{
    display: none !important;
}


