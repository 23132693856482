.dates-container{
    padding: 3rem;
    max-width: 1500px;
    margin: 0 auto;
}

.dates-form{display: flex;flex-direction: column;align-items: center;gap: .5rem;width: 80%;margin: 0 auto;padding: 2rem;background: #f7f7f7;border-radius: 1rem;}
.dates-form input{padding: .5rem 1rem;border-radius: .3rem;}
.dates-form .inputfield{
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    gap: .5rem;
}
.inputfield.botones {
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}

.form-header {
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    margin: 2rem;
    gap: 2rem;
}
.form-description {
    padding-left: .5rem;
    border-left: 1px solid;
}
.dates-form button.btn.btn-primary{
    margin: 0;
}

.modes{
    display: flex;
    justify-content: space-between;
}
.modes .mode{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    border: 1.5px solid transparent;

}
.modes .mode.selected{
    border: 1.5px solid var(--oro);
}
.modes .mode :is(img, i){
    max-width: 50px;
    font-size: 3rem;
    color: var(--azul);
}

