.details-container{
    padding: 1rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 1150px;
    margin: 0 auto;
}

.recomendations ul {
    list-style: none;
    padding: 0;
    /* gap: 1rem; */
    display: flex;
    flex-direction: column;
  }
  
 .recomendations ul li:before {
    content: '✓';
  }
.details-header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.details-header img{
    max-width: 200px;
    height: 100%;
    max-height: 110px;
}
.date {
    border-left: 1px solid;
    padding-left: 2rem;
}
.place{
    display: flex;
    gap: 2rem;
    flex-direction: column;
}

.status{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}
.status .info{
    display: flex;
    align-items: baseline;
    flex-direction: row;
    gap: 1rem;
}

.status .info .dot{
    width: 10px;
    height: 10px;
    border-radius: 2rem;
}
.status .info .red{
    background: red;
}
.status .info .green{
    background: green;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
    bottom: 7px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    margin: 8px;
    border: 8px solid var(--oro);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--oro) transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }